import React, { Component } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Collapse, Navbar, Nav, NavItem } from 'reactstrap'
import HamburgerMenu from 'react-hamburger-menu'
import { Paths, Text } from '../../Constants.jsx'

export class NavMenu extends Component {
  constructor (props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false
    }
  }

  toggle () {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  render () {
    return (
      <div>
        <Navbar color='dark' dark>
          <HamburgerMenu isOpen={this.state.isOpen} menuClicked={this.toggle.bind(this)} width={18} height={15} strokeWidth={1} rotate={0} color='white' borderRadius={0} animationDuration={0.5} />
          <RouterLink to={Paths.HOME} className='mybrand' onClick={this.toggle.bind(this)}>{Text.TITLE}</RouterLink>
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className='left' navbar>
              <NavItem>
                <RouterLink to={Paths.HOME} className='mynavitem' onClick={this.toggle.bind(this)}>Home</RouterLink>
              </NavItem>
              <NavItem>
                <RouterLink to={Paths.ABOUT} className='mynavitem' onClick={this.toggle.bind(this)}>About</RouterLink>
              </NavItem>
              <NavItem>
                <RouterLink to={Paths.CONTACT} className='mynavitem' onClick={this.toggle.bind(this)}>Contact</RouterLink>
              </NavItem>
              <NavItem>
                <RouterLink to={Paths.PRIVACY} className='mynavitem' onClick={this.toggle.bind(this)}>Privacy</RouterLink>
              </NavItem>
              <NavItem>
                <RouterLink to={Paths.TERMS_OF_USE} className='mynavitem' onClick={this.toggle.bind(this)}>Terms of Use</RouterLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    )
  }
}
