import React, { Component } from 'react'
import { Scene } from '../../scenes/Scene.jsx'
import { Paths } from '../../Constants.jsx'
import { Metadata } from '../components/Metadata.jsx'
import { withRouter } from '../components/Utilities.jsx'

class NotFound extends Component {
  render () {
    const title = 'URL Not Found'
    return (
      <Scene>
        <Metadata title={title} path={Paths.CONTACT} />
        <div>
          <br />
          <br />
          <h2>Sorry, not found</h2>
          <br />
          <br />
        </div>
      </Scene>
    )
  }
}

export default withRouter(NotFound)
