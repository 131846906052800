import React, { Component } from 'react'
import { Scene } from '../../scenes/Scene.jsx'
import { Metadata } from '../components/Metadata.jsx'
import { withRouter } from '../components/Utilities.jsx'
import { Paths, Text } from '../../Constants.jsx'

class Contact extends Component {
  render () {
    const title = 'Contact'
    const href = 'mailto:' + Text.EMAIL_CONTACT + '?subject=Contact ' + Text.TITLE

    return (
      <Scene>
        <div className='left page'>
          <Metadata title={title} path={Paths.CONTACT} />
          <hr />
          <h2>Contact</h2>
          <hr />
          <p>
            We'd Love to Hear From You!
          </p>
          <p>
            Email:
            <a className='mycontact' href={href}>Contact {Text.TITLE}</a>
          </p>
        </div>
      </Scene>
    )
  }
}

export default withRouter(Contact)
