import Beastoic from './scenes/Beastoic.jsx'
import Consulting from './scenes/Consulting.jsx'
import Daam from './scenes/Daam.jsx'
import Engineering from './scenes/Engineering.jsx'
import Staffing from './scenes/Staffing.jsx'

import About from './common-react-client/scenes/About.jsx'
import Contact from './common-react-client/scenes/Contact.jsx'
import NotFound from './common-react-client/scenes/NotFound.jsx'
import Privacy from './common-react-client/scenes/Privacy.jsx'
import TermsOfUse from './common-react-client/scenes/TermsOfUse.jsx'

export const Amazon = {
  FIND_IT_URL: 'https://www.amazon.com/ref=as_li_ss_tl?ie=UTF8&linkCode=ll2&tag=beastoic-20&linkId=5f29838b3fd21c4eca0150363af1b00f&language=en_US'
}

export const Site = {
  URL: 'https://tekana.com'
}

export const Text = {
  ABBREVIATION: 'TEKANA',
  DESCRIPTION: 'We are involved in providing information technology consulting, resource staffing and custom software engineering services.',
  EMAIL_CONTACT: 'contact@tekana.com',
  KEYWORDS: 'technology,consulting,resource,staffing,software,engineering,services',
  TITLE: 'TEKANA Corporation'
}

export const TabGroups = {
  HOME: 'home',
  NONE: 'n/a',
  OTHER: 'other'
}

export const Paths = {
  ABOUT: '/about',
  BEASTOIC: '/beastoic',
  CONTACT: '/contact',
  CONSULTING: '/consulting',
  DAAM: '/daam',
  ENGINEERING: '/engineering',
  HOME: '/',
  NOT_FOUND: '/x',
  PRIVACY: '/privacy',
  STAFFING: '/staffing',
  TERMS_OF_USE: '/terms_of_use'
}

export const TabGroupHome = [
  Paths.CONSULTING,
  Paths.ENGINEERING,
  Paths.STAFFING
]

export const Routes = [
  {
    path: Paths.ABOUT,
    component: About,
    tabGroup: TabGroups.NONE
  }, {
    path: Paths.BEASTOIC,
    component: Beastoic,
    tabGroup: TabGroups.OTHER
  }, {
    path: Paths.CONTACT,
    component: Contact,
    tabGroup: TabGroups.NONE
  }, {
    path: Paths.DAAM,
    component: Daam,
    tabGroup: TabGroups.OTHER
  }, {
    path: Paths.PRIVACY,
    component: Privacy,
    tabGroup: TabGroups.NONE
  }, {
    path: Paths.TERMS_OF_USE,
    component: TermsOfUse,
    tabGroup: TabGroups.NONE
  }, {
    path: Paths.CONSULTING,
    homepath: Paths.HOME,
    component: Consulting,
    tabGroup: TabGroups.HOME,
    tabMetaTitle: 'Consulting'
  }, {
    path: Paths.ENGINEERING,
    component: Engineering,
    tabGroup: TabGroups.HOME,
    tabMetaTitle: 'Engineering'
  }, {
    path: Paths.STAFFING,
    component: Staffing,
    tabGroup: TabGroups.HOME,
    tabMetaTitle: 'Staffing'
  }, {
    path: Paths.HOME,
    component: Consulting,
    tabGroup: TabGroups.NONE,
    tabMetaTitle: 'Consulting'
  }, {
    path: Paths.NOT_FOUND,
    component: NotFound,
    tabGroup: TabGroups.NONE
  }

]

export function dummy () {
  return 'dummy'
}
