import { Component } from 'react'

import { Routes, Route } from 'react-router-dom'

import { Routes as AppRoutes } from '../../Constants.jsx'

export class Router extends Component {
  render () {
    let key = 0
    return (
      <Routes>
        {
        AppRoutes.map(route => (<Route
          path={route.path} element={<route.component route={
            route
          }
                                     />} key={key++}
                                />))
      }
      </Routes>
    )
  }
}
