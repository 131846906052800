import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Site, Text } from '../../Constants.jsx'

export class Metadata extends Component {
  render () {
    const title = this.props.title
    const fullpath = Site.URL + this.props.path
    const description = (this.props.description) ? this.props.description : Text.DESCRIPTION
    const keywords = (this.props.keywords) ? 'stoic,' + this.props.keywords : Text.KEYWORDS
    const titleTemplate = Text.TITLE + ' | %s'

    return (
      <Helmet titleTemplate={titleTemplate} defaultTitle={Text.TITLE}>
        <html lang='en' />
        <link rel='canonical' href={fullpath} />
        <link rel='icon' href='/favicon.ico' type='image/x-icon' />
        <link rel='manifest' href='/manifest.json' />
        <link rel='shortcut icon' href='/favicon.ico' type='image/x-icon' />
        <meta charset='utf-8' />
        <meta name='description' content={description} />
        <meta name='keywords' content={keywords} />
        <meta name='theme-color' content='#000000' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <noscript>You need to enable JavaScript to run this app.</noscript>
        <title>{title}</title>
      </Helmet>
    )
  }
}
