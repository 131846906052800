import React, { Component } from 'react'
import { Scene } from '../../scenes/Scene.jsx'
import { Metadata } from '../components/Metadata.jsx'
import { withRouter } from '../components/Utilities.jsx'
import { Paths, Text } from '../../Constants.jsx'

class Privacy extends Component {
  render () {
    const title = 'Privacy'

    return (
      <Scene>
        <div className='left page'>
          <Metadata title={title} path={Paths.PRIVACY} />
          <hr />
          <h2>Privacy</h2>
          <hr />
          <strong>
            Last Updated: 01 August 2021
          </strong>

          <p />

          <strong>
            Privacy Statement of {Text.TITLE} ({Text.ABBREVIATION})
          </strong>

          <p>
            Our privacy policy covers {Text.TITLE} and its Web sites:
          </p>

          <strong>
            Providing Visitors with Anonymous Access
          </strong>

          <p>
            You can access our Web site home page and browse our site without disclosing your personal data.
          </p>

          <strong>
            The services and links of our Web site
          </strong>

          <p>
            Our Web site does not enable our visitors to communicate with other visitors or to post information to be accessed by others.
            <br />
            Our Web site does not include links to third-party Web Service providers.
          </p>

          <strong>
            Automatic Collection of Information
          </strong>

          <p>
            We do not automatically log personal data nor do we link information automatically logged by other means with personal data about specific individuals.
          </p>

          <strong>
            Data Collection and Purpose Specification
          </strong>

          <p>
            We do not collect any personal data from our visitors when they use our services.
            <br />
            We do not collect information about our visitors from other sources, such as public records or bodies, or private organizations.
          </p>

          <strong>
            Privacy Support
          </strong>

          <p>
            If you have a question or concern about our privacy policy, please contact: {Text.EMAIL_CONTACT}
          </p>
        </div>
      </Scene>
    )
  }
}

export default withRouter(Privacy)
