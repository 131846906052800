import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Site, Text } from '../../Constants.jsx'

export class Footer extends Component {
  render () {
    const dt = new Date()
    const yr = dt.getFullYear()

    return (
      <div className='container'>
        <footer className='row center'>
          <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
            <hr />
            <div>
              <br />
              <a href={Site.TWITTER_URL} target='_blank' rel='noopener noreferrer'><FontAwesomeIcon
                icon={['fab', 'twitter']} fixedWidth style={{
                  color: 'blue'
                }} size='lg'
                                                                                   />
              </a>
              {'  '}
              <a href={Site.INSTAGRAM_URL} target='_blank' rel='noopener noreferrer'><FontAwesomeIcon
                icon={['fab', 'instagram']} fixedWidth style={{
                  color: 'orange'
                }} size='lg'
                                                                                     />
              </a>
            </div>
            Copyright&nbsp;&copy;&nbsp;{yr}&nbsp;{Text.TITLE}
          </div>
        </footer>
      </div>
    )
  }
}
