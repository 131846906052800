import React, { Component } from 'react'
import { NavMenu as Header } from '../common-react-client/components/NavMenu.jsx'
import { Footer } from '../common-react-client/components/Footer.jsx'
import { Metadata } from '../common-react-client/components/Metadata.jsx'

export class Scene extends Component {
  render () {
    return (
      <div>
        <Metadata title='test' path='https://tekana.com/x/' />

        <Header />

        {this.props.children}

        <Footer />
      </div>
    )
  }
}
