import React, { Component } from 'react'
import { Scene } from '../../scenes/Scene.jsx'
import { Metadata } from '../components/Metadata.jsx'
import { withRouter } from '../components/Utilities.jsx'
import { Paths, Text } from '../../Constants.jsx'
import metadata from '../../metadata.json'

class About extends Component {
  render () {
    const title = 'About'

    return (
      <Scene>
        <div className='left page'>
          <Metadata title={title} path={Paths.ABOUT} />
          <hr />
          <h2>About</h2>
          <hr />
          <p>
            Welcome to {Text.TITLE}.
          </p>
          <p>
            {Text.DESCRIPTION}
          </p>
        </div>
        <br />
        <hr />
        <br />
        <p>
          Version {metadata.majorVersion}.{metadata.minorVersion}.{metadata.patchVersion}.{metadata.buildNumber}-{metadata.buildTag}
        </p>
      </Scene>
    )
  }
}

export default withRouter(About)
