import React, { Component } from 'react'
import { Scene } from '../../scenes/Scene.jsx'
import { Paths, Site, Text } from '../../Constants.jsx'
import { Metadata } from '../components/Metadata.jsx'
import { withRouter } from '../components/Utilities.jsx'

class TermsOfUse extends Component {
  render () {
    const title = 'Terms of Use'

    return (
      <Scene>
        <div className='left page'>
          <Metadata title={title} path={Paths.TERMS_OF_USE} />
          <h2>
            Terms of Use
          </h2>
          <hr />
          <p>
            <strong>Last updated: ​05 August 2021</strong>
          </p>
          <p>
            Please read these Terms of Use ("Terms", "Terms of Use") carefully before using the {Site.URL}
            website (the "Service") operated by ​{Text.TITLE}
            ({Text.ABBREVIATION}).
            <br />
            Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.
            <br />
            By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.
          </p>
          <p>
            <strong>
              Termination
            </strong>
          </p>
          <p>
            We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.
            <br />
            All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
          </p>
          <p>
            <strong>
              Content
            </strong>
          </p>
          <p>
            Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material ("Content"). You are responsible for complying with all applicable copyright and privacy laws.
          </p>
          <p />
          <p>
            <strong>
              Links To Other Web Sites
            </strong>
          </p>
          <p>
            Our Service may contain links to third-party web sites or services that are not owned or controlled by ​{Text.ABBREVIATION}.
            <br />
            ​{Text.ABBREVIATION}
            has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that ​{Text.ABBREVIATION}
            shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.
          </p>
          <p>
            <strong>
              Indemnification
            </strong>
          </p>
          <p>
            You agree to defend, indemnify and hold harmless from and against any and all claims, damages, costs and expenses, including attorneys' fees, arising from or related to your use of our Website or any Contracts or Services you purchase through it.
          </p>
          <p>
            <strong>
              Age of Consent
            </strong>
          </p>
          <p />
          <p>
            By using the Service, you represent that you are at least 18 years of age.
          </p>
          <p />
          <p>
            <strong>
              Changes
            </strong>
          </p>
          <p>
            We may amend this Agreement at any time by posting the amended terms on our Website. We may or may not post notices on the homepage of our Website when such changes occur.
          </p>
          <p>
            <strong>
              Contact Us
            </strong>
          </p>
          <p>
            If you have any questions about these Terms, please contact us.
          </p>
        </div>
      </Scene>
    )
  }
}

export default withRouter(TermsOfUse)
