import React, { Component } from 'react'
import { Scene } from './Scene.jsx'

import { Container, Nav, NavLink } from 'reactstrap'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { Routes as AppRoutes, TabGroups, Text } from '../Constants.jsx'
import { Metadata } from '../common-react-client/components/Metadata.jsx'
import { withRouter } from '../common-react-client/components/Utilities.jsx'
import picture from '../images/clock.jpg'

class Engineering extends Component {
  render () {
    const location = this.props.match.location.pathname
    let key = 0
    const title = Text.TITLE

    const appRoutes = []
    AppRoutes.forEach(function (appRoute) {
      if (appRoute.tabGroup === TabGroups.HOME) {
        appRoutes.push(appRoute)
      }
    })

    return (
      <Scene>
        <Container fluid>
          <Metadata title={title} path={location} description='Custom Software Engineering' keywords='Custom,Software,Engineering' />
          <br />
          <Nav tabs>
            {
            appRoutes.map(appRoute => (
              <NavLink
                key={key++} tag={Link} to={appRoute.path} className={classnames({
                  active: location === appRoute.path || location === appRoute.homepath
                })}
              >
                {appRoute.tabMetaTitle}
              </NavLink>))
          }
          </Nav>

          <div className='section'>
            <div className='row left'>
              <div className='col-lg-8 col-md-12 col-sm-12'>
                <img className='img-fluid' src={picture} alt='software engineering' width='700' height='225' />
              </div>
              <div className='col-lg-4 col-md-8 col-sm-12'>
                <h1>Custom Software Engineering</h1>
                <ul>
                  <li>Requirement Analysis</li>
                  <li>Technical Design</li>
                  <li>Agile Development</li>
                  <li>Quality Assurance Services</li>
                </ul>
              </div>
            </div>
          </div>
        </Container>
      </Scene>
    )
  }
}

export default withRouter(Engineering)
