import React, { Component } from 'react'
import { Scene } from './Scene.jsx'

import { Container, Nav, NavLink } from 'reactstrap'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { Routes as AppRoutes, TabGroups, Text } from '../Constants.jsx'
import { Metadata } from '../common-react-client/components/Metadata.jsx'
import { withRouter } from '../common-react-client/components/Utilities.jsx'

class Beastoic extends Component {
  render () {
    const location = this.props.match.location.pathname
    let key = 0
    const title = Text.TITLE

    const appRoutes = []
    AppRoutes.forEach(function (appRoute) {
      if (appRoute.tabGroup === TabGroups.HOME) {
        appRoutes.push(appRoute)
      }
    })

    return (
      <Scene>
        <Container fluid>
          <Metadata title={title} path={location} />
          <br />
          <Nav tabs>
            {
            appRoutes.map(appRoute => (
              <NavLink
                key={key++} tag={Link} to={appRoute.path} className={classnames({
                  active: location === appRoute.path || location === appRoute.homepath
                })}
              >
                {appRoute.tabMetaTitle}
              </NavLink>))
          }
          </Nav>

          <div className='section'>
            <div className='row left'>
              <div className='col-lg-8 col-md-12 col-sm-12'>Stoicism rocks!</div>
              <div className='col-lg-4 col-md-8 col-sm-12'>
                <a href='https://beastoic.com/' target='_blank' rel='noopener noreferrer'>beastoic</a>
                <br />
                <a href='https://beastoic.com/books/modern' target='_blank' rel='noopener noreferrer'>books/modern</a>
                <br />
                <a href='https://beastoic.com/books/classic' target='_blank' rel='noopener noreferrer'>books/classic</a>
                <br />
                <a href='https://beastoic.com/books/beast' target='_blank' rel='noopener noreferrer'>books/beast</a>
                <br />
                <a href='https://beastoic.com/links/stoic' target='_blank' rel='noopener noreferrer'>links/stoic</a>
                <br />
                <a href='https://beastoic.com/podcasts/stoic' target='_blank' rel='noopener noreferrer'>podcasts/stoic</a>
                <br />
                <a href='https://beastoic.com/quotes/cato' target='_blank' rel='noopener noreferrer'>quotes/cato</a>
                <br />
                <a href='https://beastoic.com/quotes/epitetus' target='_blank' rel='noopener noreferrer'>quotes/epitetus</a>
                <br />
                <a href='https://beastoic.com/quotes/jocko/willink' target='_blank' rel='noopener noreferrer'>quotes/jocko/willink</a>
                <br />
                <a href='https://beastoic.com/quotes/marcus/aurelieus' target='_blank' rel='noopener noreferrer'>quotes/marcus/aurelieus</a>
                <br />
                <a href='https://beastoic.com/quotes/seneca' target='_blank' rel='noopener noreferrer'>quotes/seneca</a>
                <br />
                <a href='https://beastoic.com/quotes/zeno/of/citium' target='_blank' rel='noopener noreferrer'>quotes/zeno/of/citium</a>
                <br />
                <a href='https://beastoic.com/stoicism/teachings' target='_blank' rel='noopener noreferrer'>stoicism/teachings</a>
                <br />
                <a href='https://beastoic.com/stoicism/virtues' target='_blank' rel='noopener noreferrer'>stoicism/virtues</a>
                <br />
                <a href='https://beastoic.com/stoicism/reminders' target='_blank' rel='noopener noreferrer'>stoicism/reminders</a>
                <br />
              </div>
            </div>
          </div>
        </Container>
      </Scene>
    )
  }
}

export default withRouter(Beastoic)
