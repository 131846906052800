import React, { Component } from 'react'

import './App.css'
import { Router } from './common-react-client/components/Router.jsx'

export class App extends Component {
  render () {
    return (
      <div className='App'>
        <Router />
      </div>
    )
  }
}
